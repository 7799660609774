import React from 'react';
import '../index.css'
import { Collection } from '../Collection'
import { Link } from 'react-router-dom';

const cats = [
  { "name": "All" },
  { "name": "Korean skincare" },
  { "name": "Gel polishes" },
  { "name": "⁠Lashes " },
  { "name": "Glues" }
]

export const CatalogPage = () => {
  const [collection, setCollection] = React.useState([])
  const [serchCollection, setSerchCollection] = React.useState('')
  const [categori, setCategori] = React.useState(0)
  const [isLoading, setIsLoading] = React.useState(true)
  const [page, setpage] = React.useState(1)


  const categoruFilter = categori ? `category=${categori}` : ''




  React.useEffect(() => {
    setIsLoading(true)
    fetch(`https://6533f11ae1b6f4c590466830.mockapi.io/Items?page=${page}&limit=3&${categoruFilter}`)
      .then((res) => res.json())
      .then((json) => {
        setCollection(json)
      })
      .catch(err => {
        alert('oшибка при получении данных')
      }).finally(() => {
        setIsLoading(false)
      })
  }, [categori, page])








  return (
    <div className="App">
      <header className=" flex items-center justify-between py-4  md:py-8 ">

        <a href="/" className="inline-flex items-center gap-2.5 text-2xl font-bold text-black md:text-3xl" aria-label="logo">
          Fialabs
        </a>

        <nav className="hidden gap-12 lg:flex">
          <a href="/" className="text-lg font-semibold text-stone-950">главная</a>
          <Link to="/catalog" className="text-lg font-semibold text-gray-600 transition duration-100 hover:text-stone-950 active:text-indigo-700">каталог</Link>
          <a href="/" className="text-lg font-semibold text-gray-600 transition duration-100 hover:text-stone-950 active:text-indigo-700">маски</a>
        </nav>

        <a href="https://www.instagram.com/fialabs.gcc?igsh=NjkybHljdjFlbGIz" className="hidden rounded-lg border-solid border-2 border-stone-950 p-2 text-center text-sm font-semibold text-gray-500 outline-none ring-stone-950 transition duration-100 hover:text-stone-950 focus-visible:ring active:text-gray-700 md:text-base lg:inline-block ring-stone-950">Написать в Instagram</a>

        <button n type="button" className="inline-flex items-center gap-2 rounded-lg bg-slate-200 p-1 text-sm font-semibold text-white ring-stone-950 hover:bg-slate-200 focus-visible:ring active:bg-slate-200 md:text-base lg:hidden">
          <a href="https://www.instagram.com/fialabs.gcc?igsh=NjkybHljdjFlbGIz"> Написать в Instagram</a>
        </button>

      </header>
      <div className="titleHead">
        <h1 className=''>Каталог</h1>
        <input value={serchCollection} onChange={(e) => setSerchCollection(e.target.value)} className="search-input" placeholder="Поиск по названию" />
      </div>
      <div className="catalog-block">
        <div className="top">
          <ul className="tags">
            {
              cats.map((data, i) =>
                <li
                  onClick={() => setCategori(i)}
                  className={categori === i ? 'active' : ''}
                  key={data.name}>{data.name}
                </li>)
            }
          </ul>

        </div>

        <div className="content">
          {
            isLoading ? (<h2>Идет загрузка...</h2>) :
              (collection.filter((obj) => obj.name.toLowerCase().includes(serchCollection.toLowerCase())).map((obj, ind) => (
                <Collection
                  CardName={obj.name}
                  img={obj.img}
                  price={obj.Price}
                  NoDiscount={obj.NoDiscount}
                  key={obj.idn}
                  obj={obj}

                />)))
          }

        </div>
      </div>

      <ul className="pagination">
        {
          [...Array(2)].map((_, i) =>
            <li
              onClick={() => setpage(i + 1)}
              className={page === i + 1 ? 'active ' : ''}>
              {i + 1}
            </li>
          )
        }
      </ul>
    </div>
  );
}

