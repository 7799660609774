import './App.css';
import React, { useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';


import { CardItemPage } from './CatalogItemPage/CardItemPage';
import { CardItemPage2 } from './CatalogItemPage/CardItemPage2'
import { CardItemPage3 } from './CatalogItemPage/CardItemPage3'
import { CardItemPage4 } from './CatalogItemPage/CardItemPage4'

import { MainPage } from './pages/MainPage'
import { CatalogPage } from './pages/CatalogPage';

import Data from './data.json'

function App() {
  const [CardItem, setCardItem] = React.useState([])


  useEffect(() => {
    setCardItem(Data.LashBrands)
  })



  return (
    <BrowserRouter>

      <Routes>
        <Route path="/" element={<MainPage data={CardItem} />} />
        <Route path="/catalog" element={<CatalogPage data={CardItem} />} />
        <Route path={`/1`} exact element={<CardItemPage data={CardItem} />} />
        <Route path={`/2`} exact element={<CardItemPage2 data={CardItem} />} />
        <Route path={`/3`} exact element={<CardItemPage3 data={CardItem} />} />
        <Route path={`/4`} exact element={<CardItemPage4 data={CardItem} />} />
      </Routes>
    </BrowserRouter>

  );
}

export default App;
