import React, { useEffect } from 'react'
import { Carousel } from '../UI/Carousel'
import { Link } from 'react-router-dom';
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react'
import '@coreui/coreui/dist/css/coreui.min.css'


import { CatalogItem } from '../components/CatalogItem'

import CollectonLeft from '../img/Rectangle 12.svg'
import CollectonRight from '../img/Rectangle 13.svg'



import Cremimg from '../img/photo_5280868573872510043_y.jpg'
import Cremimg2 from '../img/photo_5280876833094620813_x.jpg'
import Cremimg3 from '../img/photo_5280876833094620817_y.jpg'
import Cremimg5 from '../img/photo_5280876833094620814_y.jpg'
import Cremimg6 from '../img/photo_5280876833094620816_y.jpg'
import Cremimg7 from '../img/photo_5280876833094620810_y.jpg'
import Cremimg4 from '../img/photo_5280876833094620815_y.jpg'
import Cremimg12 from '../img/CremGroup3.svg'




export const MainPage = ({ data }) => {
  const [CardItem, setCardItem] = React.useState([])

  const [visible, setVisible] = React.useState(false)
  useEffect(() => {
    setCardItem(data)
  })


  const cats = [
    { "filter": "Скрабы" },
    { "filter": "Рестницы" },
    { "filter": "Ремуверы " },
    { "filter": "Крема" }
  ]




  return (
    <React.Fragment>

      {/* hero */}
      <div className="bg-white pb-6 sm:pb-8 lg:pb-12">
        <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
          <header className="mb-8 flex items-center justify-between py-4 md:mb-12 md:py-8 xl:mb-16">

            <a href="/" className="inline-flex items-center gap-2.5 text-2xl font-bold text-black md:text-3xl" aria-label="logo">
              Fialabs
            </a>

            <nav className="hidden gap-12 lg:flex">
              <a href="/" className="text-lg font-semibold text-stone-950">главная</a>
              <Link to="/catalog" className="text-lg font-semibold text-gray-600 transition duration-100 hover:text-stone-950 active:text-indigo-700">каталог</Link>
              <a href="/" className="text-lg font-semibold text-gray-600 transition duration-100 hover:text-stone-950 active:text-indigo-700">маски</a>
            </nav>

            <a href="https://www.instagram.com/fialabs.gcc?igsh=NjkybHljdjFlbGIz" className="hidden rounded-lg border-solid border-2 border-stone-950 p-2 text-center text-sm font-semibold text-gray-500 outline-none ring-stone-950 transition duration-100 hover:text-stone-950 focus-visible:ring active:text-gray-700 md:text-base lg:inline-block ring-stone-950">Написать в Instagram</a>

            <button n type="button" className="inline-flex items-center gap-2 rounded-lg bg-slate-200 p-1 text-sm font-semibold text-white ring-stone-950 hover:bg-slate-200 focus-visible:ring active:bg-slate-200 md:text-base lg:hidden">
              <a href="https://www.instagram.com/fialabs.gcc?igsh=NjkybHljdjFlbGIz"> Написать в Instagram</a>
            </button>

          </header>

          <section className="mb-8 flex flex-col justify-between gap-6 sm:gap-10 md:mb-16 md:gap-16 lg:flex-row">

            <div className="flex flex-col justify-center sm:text-center lg:py-12 lg:text-left xl:w-5/12">

              <h1 className="mb-8 text-4xl font-bold text-black sm:text-5xl md:mb-12 md:text-6xl">Разные крема для любой кожи</h1>
              <p className="mb-8 leading-relaxed text-gray-500 md:mb-12 lg:w-4/5 xl:text-lg">Мы собрали лучшие крема и маски от лучших брендов, чтобы порадовать вас свежими новинками. </p>

              <div className="flex flex-col gap-2.5 sm:flex-row sm:justify-center lg:justify-start">
                <a href="/" className="inline-block rounded-lg bg-slate-950 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-indigo-300 transition duration-100 hover:bg-gray-700 focus-visible:ring active:bg-gray-700 md:text-base"> <Link style={{ color: "#fff" }} to='/catalog'>Смотреть каталог</Link></a>

              </div>
            </div>

            <div className="h-80 overflow-hidden rounded-lg bg-gray-100 shadow-lg lg:h-96 xl:w-5/12">
              <Carousel />
            </div>

          </section>

          <section className="flex flex-col items-center justify-between gap-10 border-t pt-8 lg:flex-row lg:gap-8">

            <div className="-mx-6 grid grid-cols-2 gap-4 md:-mx-8 md:flex md:divide-x">
              <div className="px-6 md:px-8">
                <span className="block text-center text-lg font-bold text-stone-950 md:text-left md:text-xl">200</span>
                <span className="block text-center text-sm font-semibold text-gray-800 md:text-left md:text-base">People</span>
              </div>

              <div className="px-6 md:px-8">
                <span className="block text-center text-lg font-bold text-stone-950 md:text-left md:text-xl">500+</span>
                <span className="block text-center text-sm font-semibold text-gray-800 md:text-left md:text-base">Projects</span>
              </div>

              <div className="px-6 md:px-8">
                <span className="block text-center text-lg font-bold text-stone-950 md:text-left md:text-xl">250+</span>
                <span className="block text-center text-sm font-semibold text-gray-800 md:text-left md:text-base">Customers</span>
              </div>

              <div className="px-6 md:px-8">
                <span className="block text-center text-lg font-bold text-stone-950 md:text-left md:text-xl">A couple</span>
                <span className="block text-center text-sm font-semibold text-gray-800 md:text-left md:text-base">Coffee breaks</span>
              </div>
            </div>

            <div className="flex items-center justify-center gap-4 lg:justify-start">
              <span className="text-sm font-semibold uppercase tracking-widest text-gray-400 sm:text-base">Social</span>
              <span className="h-px w-12 bg-gray-200"></span>

              <div className="flex gap-4">
                <a href="https://www.instagram.com/fialabs.gcc?igsh=NjkybHljdjFlbGIz" target="_blank" className="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
                  <svg className="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                  </svg>
                </a>

                <a href="/" target="_blank" className="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
                  <svg className="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                  </svg>
                </a>

                <a href="/" target="_blank" className="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
                  <svg className="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                  </svg>
                </a>

                <a href="/" target="_blank" className="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
                  <svg className="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
                  </svg>
                </a>
              </div>
            </div>

          </section>
        </div >
      </div >
      {/* hero */}


      {/* CartSection */}
      <div className="bg-white py-6 sm:py-8 lg:py-12">
        <div className="mx-auto max-w-screen-2xl px-4 md:px-8">

          <div className="mb-2 flex items-center gap-16 md:mb-16">
            <h2 className="mb-1 text-left text-2xl font-bold text-gray-800 md:mb-1 lg:text-3xl">Горячие поступления</h2>
          </div>



          <div className="grid gap-x-4 gap-y-8 sm:grid-cols-2 md:gap-x-6 lg:grid-cols-3 xl:grid-cols-4">
            {
              CardItem.map((data, index) => <CatalogItem data={data} />)
            }

          </div>

          <div style={{ display: "flex", justifyContent: "center", marginTop: " 30px" }} className="btnBlock">

            <button className="inline-block flex-1 rounded-lg bg-indigo-500 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-indigo-300 transition duration-100 hover:bg-indigo-600 focus-visible:ring active:bg-indigo-700 sm:flex-none md:text-base">
              <Link style={{ color: "#fff" }} to='/catalog'>Смотреть больше</Link>
            </button>
          </div>

        </div>
      </div>
      {/* CartSection */}


      {/* CollectionSection */}
      <div className="bg-white py-6 sm:py-8 lg:py-12">
        <div className="mx-auto max-w-screen-2xl px-4 md:px-8">



          <div className="grid gap-6 sm:grid-cols-2">

            <a href="/" className="group relative flex h-96 items-end overflow-hidden rounded-lg bg-gray-100 p-4 shadow-lg">
              <img src={CollectonLeft} loading="lazy" alt="Photo by Fakurian Design" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />

              <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>

              <div className="relative flex flex-col">
                <span className="text-gray-300">Home</span>
                <span className="text-lg font-semibold text-white lg:text-xl">Decoration</span>
              </div>
            </a>

            <a href="/" className="group relative flex h-96 items-end overflow-hidden rounded-lg bg-gray-100 p-4 shadow-lg">
              <img src={CollectonRight} loading="lazy" alt="Photo by Fakurian Design" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />

              <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>

              <div className="relative flex flex-col">
                <span className="text-gray-300">Modern</span>
                <span className="text-lg font-semibold text-white lg:text-xl">Furniture</span>
              </div>
            </a>

          </div>
        </div>
      </div>
      {/* CollectionSection */}


      {/* AsortySection */}
      <div className="bg-white py-6 sm:py-8 lg:py-12">
        <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
          <div className="mb-6 flex items-end justify-between gap-4">
            <h2 className="text-2xl font-bold text-gray-800 lg:text-3xl">Популярные товары</h2>
          </div>

          <div className="grid gap-x-4 gap-y-8 sm:grid-cols-2 md:gap-x-6 lg:grid-cols-3 xl:grid-cols-4">

            <div onClick={() => setVisible(!visible)}>
              <div className="group relative mb-2 block h-80 overflow-hidden rounded-lg bg-gray-100 lg:mb-3">
                <img src={Cremimg} loading="lazy" alt="Photo by Rachit Tank" className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />

                <span className="absolute left-0 top-0 rounded-br-lg bg-red-500 px-3 py-1.5 text-sm uppercase tracking-wider text-white">sale</span>
              </div>

              <div>
                <a href="/" className="hover:gray-800 mb-1 text-gray-500 transition duration-100 lg:text-lg">Timely Watch</a>

                <div className="flex items-end gap-2">
                  <span className="font-bold text-gray-800 lg:text-lg">$15.00</span>
                  <span className="mb-0.5 text-red-500 line-through">$30.00</span>
                </div>
              </div>
            </div>

            <div onClick={() => setVisible(!visible)}>
              <div className="group relative mb-2 block h-80 overflow-hidden rounded-lg bg-gray-100 lg:mb-3">
                <img src={Cremimg2} loading="lazy" alt="Photo by Galina N" className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
              </div>

              <div>
                <a href="/" className="hover:gray-800 mb-1 text-gray-500 transition duration-100 lg:text-lg">Fancy Plant</a>

                <div className="flex items-end gap-2">
                  <span className="font-bold text-gray-800 lg:text-lg">$9.00</span>
                </div>
              </div>
            </div>

            <div onClick={() => setVisible(!visible)}>
              <div className="group relative mb-2 block h-80 overflow-hidden rounded-lg bg-gray-100 lg:mb-3">
                <img src={Cremimg3} loading="lazy" alt="Photo by eniko kis" className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
              </div>

              <div>
                <a href="/" className="hover:gray-800 mb-1 text-gray-500 transition duration-100 lg:text-lg">Elderly Cam</a>

                <div className="flex items-end gap-2">
                  <span className="font-bold text-gray-800 lg:text-lg">$45.00</span>
                </div>
              </div>
            </div>

            <div onClick={() => setVisible(!visible)}>
              <div className="group relative mb-2 block h-80 overflow-hidden rounded-lg bg-gray-100 lg:mb-3">
                <img src={Cremimg4} loading="lazy" alt="Photo by Irene Kredenets" className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
              </div>

              <div>
                <a href="/" className="hover:gray-800 mb-1 text-gray-500 transition duration-100 lg:text-lg">Shiny Shoe</a>

                <div className="flex items-end gap-2">
                  <span className="font-bold text-gray-800 lg:text-lg">$29.00</span>
                </div>
              </div>
            </div>

            <div onClick={() => setVisible(!visible)}>
              <div className="group relative mb-2 block h-80 overflow-hidden rounded-lg bg-gray-100 lg:mb-3">
                <img src={Cremimg5} loading="lazy" alt="Photo by Charles Deluvio" className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
              </div>

              <div>
                <a href="/" className="hover:gray-800 mb-1 text-gray-500 transition duration-100 lg:text-lg">Spiky Plant</a>

                <div className="flex items-end gap-2">
                  <span className="font-bold text-gray-800 lg:text-lg">$4.00</span>
                </div>
              </div>
            </div>

            <div onClick={() => setVisible(!visible)}>
              <div className="group relative mb-2 block h-80 overflow-hidden rounded-lg bg-gray-100 lg:mb-3">
                <img src={Cremimg7} loading="lazy" alt="Photo by Fernando Lavin" className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
              </div>

              <div>
                <a href="/" className="hover:gray-800 mb-1 text-gray-500 transition duration-100 lg:text-lg">Wieldy Film</a>

                <div className="flex items-end gap-2">
                  <span className="font-bold text-gray-800 lg:text-lg">$19.00</span>
                </div>
              </div>
            </div>

            <div onClick={() => setVisible(!visible)}>
              <div className="group relative mb-2 block h-80 overflow-hidden rounded-lg bg-gray-100 lg:mb-3">
                <img src={Cremimg6} loading="lazy" alt="Photo by Kiran CK" className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
                <span className="absolute left-0 top-0 rounded-br-lg bg-red-500 px-3 py-1.5 text-sm uppercase tracking-wider text-white">sale</span>
              </div>

              <div>
                <a href="/" className="hover:gray-800 mb-1 text-gray-500 transition duration-100 lg:text-lg">Sturdy Stand</a>

                <div className="flex items-end gap-2">
                  <span className="font-bold text-gray-800 lg:text-lg">$12.00</span>
                  <span className="mb-0.5 text-red-500 line-through">$24.00</span>
                </div>
              </div>
            </div>

            <div onClick={() => setVisible(!visible)}>
              <div className="group relative mb-2 block h-80 overflow-hidden rounded-lg bg-gray-100 lg:mb-3">
                <img src={Cremimg12} loading="lazy" alt="Photo by Fakurian Design" className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
              </div>

              <div>
                <a href="/" className="hover:gray-800 mb-1 text-gray-500 transition duration-100 lg:text-lg">Lazy Bottle</a>

                <div className="flex items-end gap-2">
                  <span className="font-bold text-gray-800 lg:text-lg">$9.00</span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      {/* AsortySection */}


      {/* StatusSection */}
      <div className="bg-white py-6 sm:py-8 lg:py-12">
        <div className="mx-auto max-w-screen-lg px-4 md:px-8">

          <div className="grid grid-cols-2 gap-6 rounded-lg  p-6 md:grid-cols-4 md:gap-8 md:p-8">

            <div className="flex flex-col items-center">
              <div className="text-xl font-bold text-black sm:text-2xl md:text-3xl">200</div>
              <div className="text-sm text-black sm:text-base">People</div>
            </div>
            <div className="flex flex-col items-center">
              <div className="text-xl font-bold text-black sm:text-2xl md:text-3xl">500+</div>
              <div className="text-sm text-black sm:text-base">People</div>
            </div>


            <div className="flex flex-col items-center">
              <div className="text-xl font-bold text-black sm:text-2xl md:text-3xl">1000+</div>
              <div className="text-sm text-black sm:text-base">Customers</div>
            </div>
            <div className="flex flex-col items-center">
              <div className="text-xl font-bold text-black sm:text-2xl md:text-3xl">A couple</div>
              <div className="text-sm text-black sm:text-base">Coffee breaks</div>
            </div>

          </div>
        </div>
      </div>
      {/* StatusSection */}


      {/* FooterSection */}
      <div className="bg-white pt-4 sm:pt-10 lg:pt-12">
        <footer className="mx-auto max-w-screen-2xl px-4 md:px-8">
          <div className="flex flex-col items-center justify-between gap-4 border-t border-b py-6 md:flex-row">

            <nav className="flex flex-wrap justify-center gap-x-4 gap-y-2 md:justify-start md:gap-6">
              <a href="/" className="text-gray-500 transition duration-100 hover:text-indigo-500 active:text-indigo-600">About</a>
              <a href="/" className="text-gray-500 transition duration-100 hover:text-indigo-500 active:text-indigo-600">Investor Relations</a>
              <a href="/" className="text-gray-500 transition duration-100 hover:text-indigo-500 active:text-indigo-600">Jobs</a>
              <a href="/" className="text-gray-500 transition duration-100 hover:text-indigo-500 active:text-indigo-600">Press</a>
              <a href="/" className="text-gray-500 transition duration-100 hover:text-indigo-500 active:text-indigo-600">Blog</a>
            </nav>

            <div className="flex gap-4">
              <a href="/" target="_blank" className="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
                <svg className="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                </svg>
              </a>

              <a href="/" target="_blank" className="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
                <svg className="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                </svg>
              </a>

              <a href="/" target="_blank" className="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
                <svg className="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                </svg>
              </a>

              <a href="/" target="_blank" className="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
                <svg className="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
                </svg>
              </a>
            </div>

          </div>

          <div className="py-8 text-center text-sm text-gray-400">© 2021 - Present Flowrift. All rights reserved.</div>
        </footer>
      </div>
      {/* FooterSection */}

      <>
        <CModal
          alignment="center"
          scrollable
          visible={visible}
          onClose={() => setVisible(false)}
          aria-labelledby="VerticallyCenteredScrollableExample2"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredScrollableExample2">Оформить заказ</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <p>
              Мы заметили, что вы оценили наш продукт, и мы хотим предложить вам возможность его приобрести. Если вы заинтересовались, напишите нам по номеру Whatsapp, и наши менеджеры с радостью помогут оформить заказ.
              <br /> <br /> В добавок, хотим обратить ваше внимание на то, что мы предлагаем гибкие условия оплаты и доставки, а также предоставляем гарантию качества на наш товар. Не упустите возможность стать обладателем этого замечательного продукта.
            </p>

          </CModalBody>
          <CModalFooter>
            <CButton color="secondary" onClick={() => setVisible(false)}>
              Закрыть
            </CButton>
            <CButton color="primary"><a style={{ color: "#fff" }} href='https://wa.me/message/AJMZS6ZJX7UFD1'>Написать на Whatsapp</a></CButton>
          </CModalFooter>
        </CModal>
      </>


    </React.Fragment>
  )
}
