import React from 'react'
import { Link } from 'react-router-dom';


export const CatalogItem = ({ data }) => {



  return (
    <Link to={`/${data._id}`} >
      <span class="group relative mb-2 block h-96 overflow-hidden rounded-lg bg-gray-100 shadow-lg lg:mb-3">
        <img src={data.img} />

        <div class="absolute left-0 bottom-2 flex gap-2">
          <span class="rounded-r-lg bg-red-500 px-3 py-1.5 text-sm font-semibold uppercase tracking-wider text-white">-50%</span>
          <span class="rounded-lg bg-white px-3 py-1.5 text-sm font-bold uppercase tracking-wider text-gray-800">New</span>
        </div>
      </span>

      <div class="flex items-start justify-between gap-2 px-2">
        <div class="flex flex-col">
          <span class="text-lg font-bold text-gray-800 transition duration-100 hover:text-gray-500 lg:text-xl">{data.name}</span>
          <span class="text-gray-500">{data.Composition}</span>
        </div>

        <div class="flex flex-col items-end">
          <span class="font-bold text-gray-600 lg:text-lg">{data.Price}</span>
          <span class="text-sm text-red-500 line-through">{data.NoDiscount}</span>
        </div>
      </div>
    </Link>
  )
}
