import React from 'react'
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react'
import '@coreui/coreui/dist/css/coreui.min.css'


export const CardItemPage2 = () => {
  const [visible, setVisible] = React.useState(false)
  return (
    <div class="bg-white pb-6 sm:pb-8 lg:pb-12">
      <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
        <header class="mb-8 flex items-center justify-between py-4 md:mb-12 md:py-8 xl:mb-16">

          <a href="/" class="inline-flex items-center gap-2.5 text-2xl font-bold text-black md:text-3xl" aria-label="logo">
            Fialabs
          </a>

          <nav class="hidden gap-12 lg:flex">
            <a href="/" class="text-lg font-semibold text-stone-950">главная</a>
            <a href="/" class="text-lg font-semibold text-gray-600 transition duration-100 hover:text-stone-950 active:text-indigo-700">каталог</a>
            <a href="/" class="text-lg font-semibold text-gray-600 transition duration-100 hover:text-stone-950 active:text-indigo-700">крема</a>
            <a href="/" class="text-lg font-semibold text-gray-600 transition duration-100 hover:text-stone-950 active:text-indigo-700">маски</a>
            <a href="/" class="text-xl font-semibold text-stone-950 transition duration-100 hover:text-stone-950 active:text-indigo-700">...</a>
          </nav>

          <a href="https://www.instagram.com/fialabs.gcc?igsh=NjkybHljdjFlbGIz" class="hidden rounded-lg border-solid border-2 border-stone-950 px-8 py-3 text-center text-sm font-semibold text-gray-500 outline-none ring-stone-950 transition duration-100 hover:text-stone-950 focus-visible:ring active:text-gray-700 md:text-base lg:inline-block ring-stone-950">Написать в Instagram</a>

          <button n type="button" class="inline-flex items-center gap-2 rounded-lg bg-slate-950 px-2.5 py-2 text-sm font-semibold text-white ring-stone-950 hover:bg-slate-950 focus-visible:ring active:text-slate-300 md:text-base lg:hidden">
            <a href="https://www.instagram.com/fialabs.gcc?igsh=NjkybHljdjFlbGIz"> Написать в Instagram</a>
          </button>

        </header>


        <div class="mx-auto max-w-full px-4 md:px-8">
          <div class="grid gap-8 md:grid-cols-2">

            <div class="grid gap-4 lg:grid-cols-5">
              <div class="order-last flex gap-4 lg:order-none lg:flex-col">
                <div class="overflow-hidden rounded-lg bg-gray-100">
                  <img src="https://basket-10.wbbasket.ru/vol1455/part145537/145537255/images/big/1.webp" loading="lazy" alt="Photo by Himanshu Dewangan" class="h-full w-full object-cover object-center" />
                </div>

                <div class="overflow-hidden rounded-lg bg-gray-100">
                  <img src="https://basket-10.wbbasket.ru/vol1455/part145537/145537255/images/big/1.webp" loading="lazy" alt="Photo by Himanshu Dewangan" class="h-full w-full object-cover object-center" />
                </div>

                <div class="overflow-hidden rounded-lg bg-gray-100">
                  <img src="https://basket-10.wbbasket.ru/vol1455/part145537/145537255/images/big/1.webp" loading="lazy" alt="Photo by Himanshu Dewangan" class="h-full w-full object-cover object-center" />
                </div>
              </div>

              <div class="relative overflow-hidden rounded-lg bg-gray-100 lg:col-span-4">
                <img src="https://basket-10.wbbasket.ru/vol1455/part145537/145537255/images/big/1.webp" loading="lazy" alt="Photo by Himanshu Dewangan" class="h-full w-full object-cover object-center" />

                <span class="absolute left-0 top-0 rounded-br-lg bg-red-500 px-3 py-1.5 text-sm uppercase tracking-wider text-white">sale</span>

                <a href="/" class="absolute right-4 top-4 inline-block rounded-lg border bg-white px-3.5 py-3 text-center text-sm font-semibold text-gray-500 outline-none ring-indigo-300 transition duration-100 hover:bg-gray-100 focus-visible:ring active:text-gray-700 md:text-base">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                  </svg>
                </a>
              </div>
            </div>

            <div class="md:py-8">

              <div class="mb-2 md:mb-8">
                <span class="mb-0.5 inline-block text-gray-500">описание</span>
                <h2 class="text-2xl font-bold text-gray-800 lg:text-3xl">Ресницы лазурные</h2>
                <span class="text-sm ">Цветные ресницы лазурного цвета натурального оттенка используются для профессионального наращивания ресниц. Ресницы имеют натуральный насыщенный лазурный цвет, подходят для любого типа наращивания и любой техники формирования пучков. Могут использоваться как начинающими мастерами, так и профессионалами высокого уровня. Лазурные ресницы помогут создать цветовой акцент на глазах, подчеркнут естественный цвет глаз и сделают образ ярче и интереснее. Также подходят для полноценного цветного наращивания ресниц. Преимущества цветных ресниц Barbara: - Созданы из лучшего южнокорейского волокна; - Стойкий изгиб, который не деформируется при носке; - Оптимальная по липкости лента; - Насыщенный цвет ресниц до самого кончика; - Высокая наполняемость ресничной ленты; - Подходят для любых техник формирования пучков. Ресницы Barbara соответствуют мировым стандартам качества. В палетке 6 линий.</span>
              </div>

              <div class="mb-6 flex items-center gap-3 md:mb-10">
                <div class="flex  items-center gap-1 rounded-full bg-indigo-500 px-2 text-white">
                  <span class="p-1 text-sm">ресницы 1 уп; подарок</span>

                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                </div>

                <span class="text-sm text-gray-500 transition duration-100">полимерное моноволокно</span>
              </div>

              <div class="mb-4">
                <div class="flex items-end gap-2">
                  <span class="text-xl font-bold text-gray-800 md:text-2xl">279 ₽</span>
                  <span class="mb-0.5 text-red-500 line-through">500 ₽</span>
                </div>

                <span class="text-sm text-gray-500">Срок годности 36 месяцев</span>
              </div>

              <div class="mb-6 flex items-center gap-2 text-gray-500">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z" />
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0" />
                </svg>

                <span class="text-sm">Республика Корея</span>
              </div>

              <>
                <CModal
                  alignment="center"
                  scrollable
                  visible={visible}
                  onClose={() => setVisible(false)}
                  aria-labelledby="VerticallyCenteredScrollableExample2"
                >
                  <CModalHeader>
                    <CModalTitle id="VerticallyCenteredScrollableExample2">Оформить заказ</CModalTitle>
                  </CModalHeader>
                  <CModalBody>
                    <p>
                      Мы заметили, что вы оценили наш продукт, и мы хотим предложить вам возможность его приобрести. Если вы заинтересовались, напишите нам по номеру Whatsapp, и наши менеджеры с радостью помогут оформить заказ.
                      <br /> <br /> В добавок, хотим обратить ваше внимание на то, что мы предлагаем гибкие условия оплаты и доставки, а также предоставляем гарантию качества на наш товар. Не упустите возможность стать обладателем этого замечательного продукта.
                    </p>

                  </CModalBody>
                  <CModalFooter>
                    <CButton color="secondary" onClick={() => setVisible(false)}>
                      Закрыть
                    </CButton>
                    <CButton color="primary"><a style={{ color: "#fff" }} href='https://wa.me/message/AJMZS6ZJX7UFD1'>Написать на Whatsapp</a></CButton>
                  </CModalFooter>
                </CModal>
              </>

              <div className="flex gap-2.5">
                <button onClick={() => setVisible(!visible)} className="inline-block flex-1 rounded-lg bg-indigo-500 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-indigo-300 transition duration-100 hover:bg-indigo-600 focus-visible:ring active:bg-indigo-700 sm:flex-none md:text-base">Добавить в корзину</button>

              </div>


            </div>

          </div>
        </div>
      </div >
    </div >
  )
}
